import React from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Layout from '../components/Layout/Layout'

const ns = `event-page`

const EventPost = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <div className={`${ns}__container`}>
          <h1 className={`${ns}__title`}>{markdownRemark.frontmatter.title}</h1>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {markdownRemark.frontmatter.sub_title}
          </ReactMarkdown>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              {markdownRemark.frontmatter.featuredimage && (
                <img
                  className="img-fluid"
                  src={markdownRemark.frontmatter.featuredimage.publicURL}
                  alt=""
                />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: markdownRemark.html,
                }}
              />
              {markdownRemark.frontmatter.rsvp_link && (
                <div className="row justify-content-center">
                  <a
                    className="button"
                    href={markdownRemark.frontmatter.rsvp_link}
                  >
                    {markdownRemark.frontmatter.rsvp_text}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EventPost

export const eventPostQuery = graphql`
  query EventPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        sub_title
        featuredimage {
          publicURL
        }
        rsvp_link
        rsvp_text
      }
      html
    }
  }
`
