import React, { useEffect, useState } from 'react'

const ns = `side-logos`

const SideLogos = () => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const handleStickyHeader = scrollY => {
      if (scrollY >= 600) {
        return setIsShown(true)
      }

      return setIsShown(false)
    }

    const handleScroll = () => {
      const currentScrollY = window.scrollY

      handleStickyHeader(currentScrollY)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setIsShown])

  return (
    <>
      <div className={`${ns}__left-logo ${isShown ? 'show' : ''}`}>
        <img src="/UM_logo.svg" alt="" />
      </div>
      <div className={`${ns}__right-logo ${isShown ? 'show' : ''}`}>
        <img src="/UM_logo.svg" alt="" />
      </div>
    </>
  )
}

export default SideLogos
